import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { Col, Container, Form, FormControl, Row, Tab, Tabs } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import { useMsal } from "@azure/msal-react";

import "../assets/css/main.scss";
import { IOrgProfileSeedInterests, 
    IOrgSeedInterestData, IOrganisation, IOrgSubmitData, IUserprofile, EUserProfileKeys, ESeedTestingDataFields, ESeedProductionAreaDataFields, EResearchOrgDataFields, ENurseryDataFields, ELandscapeRestorationDataFields, ESeedBankDataFields, EOtherServiceDataFields } from "../models/cmsModels";
import { EFieldName, EOrgProfileSeedInterests} from "../constants/enums";
import { UpdateUserRecord, getRecordsByParam, cmsCollections, getRecord } from "../services/cmsService";
import SeedCollector from '../components/OrgProfile/OrgSeedInterest-SeedCollector';
import SeedProductionArea from "../components/OrgProfile/OrgSeedInterest-SeedProductionArea";
import ResearchOrganisation from "../components/OrgProfile/OrgSeedInterest-ResearchOrganisation";
import Nursery from "../components/OrgProfile/OrgSeedInterest-Nursery";
import LandscapeRestoration from "../components/OrgProfile/OrgSeedInterest-LandscapeRestoration";
import SeedBank from "../components/OrgProfile/OrgSeedInterest-SeedBank";
import SeedTesting from "../components/OrgProfile/OrgSeedInterest-SeedTesting";
import OtherService from "../components/OrgProfile/OrgSeedInterest-OtherService";
import { getIsUserAuthenticated, getToken, getUser } from "../services/auth";

import Layout from "../components/Layout";
import { global, loaderStyle, waitingText } from '../constants/common';
import ToastContainer, { EMessageModeEnum } from "../components/shared/Toast/ToastContainer";
import { loginRequest } from "../msal/authConfig";

const ManageOrg = () => {

    const isAuthenticated = getIsUserAuthenticated();

    const [ isLoading, setIsLoading ] = useState(false);
    const [userprofile, setUserProfile] = useState(undefined as IUserprofile);
    const [userID, setUserID] = useState(undefined);
    const [userOrgID, setUserOrgID] = useState(undefined);
    const [key, setKey] = useState('contactInfo');
    const [message, setMessage] = useState('');
    const [ messageMode, setMessageMode ] = useState(EMessageModeEnum.SUCCESS);
    const [seedInterestFlags, setSeedInterestFlags] = useState({} as IOrgProfileSeedInterests);

    const { register, handleSubmit, setValue } = useForm();

    const token = getToken();
    const { instance } = useMsal();

    useEffect(() => {
        const fn = async () => {
            const token = getToken();

            const user = getUser();

            if (!token) {
                instance.loginRedirect(loginRequest);
            }

            if (!userprofile && token) {
                setIsLoading(true);
                const results = await getRecordsByParam(token, cmsCollections.Userprofiles, "email", user.email);

                if(results && results.userprofiles && results.userprofiles.length) 
                {
                    const data: IUserprofile = results.userprofiles[0];

                    setUserID(data.id);
                    setUserOrgID(data.organisationId);

                    const orgResult = await getRecord('', cmsCollections.Organisations, data.organisationId).then(res => res ? res.data : {});
                    setIsLoading(false);

                    setUserProfile(data);
                    if(orgResult) {
                        setValue("orgName", orgResult.orgName); 
                        setValue("orgAddress", orgResult.orgAddress); 
                        setValue("orgPostcode", orgResult.orgPostcode); 
                        setValue("orgState", orgResult.orgState); 
                        setValue("orgContactNumber", orgResult.orgContactNumber); 
                        setValue("orgWebsite", orgResult.orgWebsite); 

                        setValue(EOrgProfileSeedInterests.isSeedCollector, orgResult.isSeedCollector);
                        setValue(EOrgProfileSeedInterests.isResearchOrganisation, orgResult.isResearchOrganisation);
                        setValue(EOrgProfileSeedInterests.isSeedBank, orgResult.isSeedBank);
                        setValue(EOrgProfileSeedInterests.isSeedProductionArea, orgResult.isSeedProductionArea);
                        setValue(EOrgProfileSeedInterests.isLandscapeRestoration, orgResult.isLandscapeRestoration);
                        setValue(EOrgProfileSeedInterests.isOther, orgResult.isOther);
                        setValue(EOrgProfileSeedInterests.isNursery, orgResult.isNursery);
                        setValue(EOrgProfileSeedInterests.isSeedTesting, orgResult.isSeedTesting);

                        const seedCollectorData = orgResult.orgData?.seedCollectorData[0];
                        if (seedCollectorData) {
                            setValue(EUserProfileKeys.isSeedCollector, orgResult.isSeedCollector);
                            setValue(EUserProfileKeys.isResearchOrganisation, orgResult.isResearchOrganisation);
                            setValue(EUserProfileKeys.isSeedBank, orgResult.isSeedBank);
                            setValue(EUserProfileKeys.isSeedProductionArea, orgResult.isSeedProductionArea);
                            setValue(EUserProfileKeys.isLandscapeRestoration, orgResult.isLandscapeRestoration);
                            setValue(EUserProfileKeys.isOther, orgResult.isOther);
                            setValue(EUserProfileKeys.isNursery, orgResult.isNursery);
                            setValue(EUserProfileKeys.isSeedTesting, orgResult.isSeedTesting);
                        }

                        // set the checkbox flags
                        setSeedInterestFlags(orgResult);
                        
                        const seedProductionAreaData = orgResult.orgData?.seedProductionAreaData?.length && orgResult.orgData?.seedProductionAreaData[0];
                        if (seedProductionAreaData) {
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaCanopyTree, seedProductionAreaData.seedProductionAreaCanopyTree)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaGroundLayer, seedProductionAreaData.seedProductionAreaGroundLayer)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaOther, seedProductionAreaData.seedProductionAreaOther)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaSeedMidStory, seedProductionAreaData.seedProductionAreaSeedMidStory)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaWetLand, seedProductionAreaData.seedProductionAreaWetLand)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaLocation, seedProductionAreaData.seedProductionAreaLocation)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaDistance, seedProductionAreaData.seedProductionAreaDistance)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaMonths, seedProductionAreaData.seedProductionAreaMonths)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaSpecialistCleaning, seedProductionAreaData.seedProductionAreaSpecialistCleaning)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaSpecialistStorage, seedProductionAreaData.seedProductionAreaSpecialistStorage)
                            setValue(ESeedProductionAreaDataFields.seedProductionAreaServiceExpertiseDescription, seedProductionAreaData.seedProductionAreaServiceExpertiseDescription)
                        }

                        const researchOrganisationData = orgResult.orgData?.researchOrganisationData?.length && orgResult.orgData?.researchOrganisationData[0];
                        if (researchOrganisationData) {
                            setValue(EResearchOrgDataFields.ResearchOrgDescription, researchOrganisationData.researchOrganisationDescription)
                        }

                        const nurserydata = orgResult.orgData?.nurseryData?.length && orgResult.orgData?.nurseryData[0] ;
                        if (nurserydata) {
                            setValue(ENurseryDataFields.nurseryServiceInNT, nurserydata.nurseryServiceInNT)
                            setValue(ENurseryDataFields.nurseryServiceInWA, nurserydata.nurseryServiceInWA)
                            setValue(ENurseryDataFields.nurseryServiceInSA, nurserydata.nurseryServiceInSA)
                            setValue(ENurseryDataFields.nurseryServiceInVIC, nurserydata.nurseryServiceInVIC)
                            setValue(ENurseryDataFields.nurseryServiceInTAS, nurserydata.nurseryServiceInTAS)
                            setValue(ENurseryDataFields.nurseryServiceInACT, nurserydata.nurseryServiceInACT)
                            setValue(ENurseryDataFields.nurseryServiceInNSW, nurserydata.nurseryServiceInNSW)
                            setValue(ENurseryDataFields.nurseryServiceInQLD, nurserydata.nurseryServiceInQLD)
                            setValue(ENurseryDataFields.nurseryCapacity, nurserydata.nurseryCapacity)
                        }

                        const landscapeRestorationData = orgResult.orgData?.landscapeRestorationData?.length && orgResult.orgData?.landscapeRestorationData[0] ;
                        if (landscapeRestorationData) {
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationNT, landscapeRestorationData.landscapeRestorationNT)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationWA, landscapeRestorationData.landscapeRestorationWA)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationSA, landscapeRestorationData.landscapeRestorationSA)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationVIC, landscapeRestorationData.landscapeRestorationVIC)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationTAS, landscapeRestorationData.landscapeRestorationTAS)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationACT, landscapeRestorationData.landscapeRestorationACT)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationNSW, landscapeRestorationData.landscapeRestorationNSW)
                            setValue(ELandscapeRestorationDataFields.landscapeRestorationQLD, landscapeRestorationData.landscapeRestorationQLD)
                        }

                        const seedBankData = orgResult.orgData?.seedBankData?.length && orgResult.orgData?.seedBankData[0] ;
                        if (seedBankData) {
                            setValue(ESeedBankDataFields.seedBankNT, seedBankData.seedBankNT)
                            setValue(ESeedBankDataFields.seedBankWA, seedBankData.seedBankWA)
                            setValue(ESeedBankDataFields.seedBankSA, seedBankData.seedBankSA)
                            setValue(ESeedBankDataFields.seedBankVIC, seedBankData.seedBankVIC)
                            setValue(ESeedBankDataFields.seedBankTAS, seedBankData.seedBankTAS)
                            setValue(ESeedBankDataFields.seedBankACT, seedBankData.seedBankACT)
                            setValue(ESeedBankDataFields.seedBankNSW, seedBankData.seedBankNSW)
                            setValue(ESeedBankDataFields.seedBankQLD, seedBankData.seedBankQLD)
                            setValue(ESeedBankDataFields.seedBankDescription, seedBankData.seedBankDescription)
                        }

                        const seedTestingData = orgResult.orgData?.seedTestingData?.length && orgResult.orgData?.seedTestingData[0];
                        if (seedTestingData) {
                            setValue(ESeedTestingDataFields.seedTestingNT, seedTestingData.seedTestingNT)
                            setValue(ESeedTestingDataFields.seedTestingWA, seedTestingData.seedTestingWA)
                            setValue(ESeedTestingDataFields.seedTestingSA, seedTestingData.seedTestingSA)
                            setValue(ESeedTestingDataFields.seedTestingVIC, seedTestingData.seedTestingVIC)
                            setValue(ESeedTestingDataFields.seedTestingTAS, seedTestingData.seedTestingTAS)
                            setValue(ESeedTestingDataFields.seedTestingACT, seedTestingData.seedTestingACT)
                            setValue(ESeedTestingDataFields.seedTestingNSW, seedTestingData.seedTestingNSW)
                            setValue(ESeedTestingDataFields.seedTestingQLD, seedTestingData.seedTestingQLD)
                        }

                        const otherServiceData = orgResult.orgData?.otherServiceData?.length && orgResult.orgData?.otherServiceData[0] ;
                        if (otherServiceData) {
                            setValue(EOtherServiceDataFields.otherServiceNT, otherServiceData.otherServiceNT)
                            setValue(EOtherServiceDataFields.otherServiceWA, otherServiceData.otherServiceWA)
                            setValue(EOtherServiceDataFields.otherServiceSA, otherServiceData.otherServiceSA)
                            setValue(EOtherServiceDataFields.otherServiceVIC, otherServiceData.otherServiceVIC)
                            setValue(EOtherServiceDataFields.otherServiceTAS, otherServiceData.otherServiceTAS)
                            setValue(EOtherServiceDataFields.otherServiceACT, otherServiceData.otherServiceACT)
                            setValue(EOtherServiceDataFields.otherServiceNSW, otherServiceData.otherServiceNSW)
                            setValue(EOtherServiceDataFields.otherServiceQLD, otherServiceData.otherServiceQLD)
                            setValue(EOtherServiceDataFields.otherServiceDescription, otherServiceData.otherServiceDescription)
                        }
                    }
                }
                else
                {
                    setIsLoading(false);
                }
            }
        };
    
        fn();
    }, [isAuthenticated]);

    const showMessage = (message: string, mode: EMessageModeEnum) => {
        setMessage(message);
        setMessageMode(mode);
    };

    const toggleSeedInterestFlag = (name: string) => {
        const val = seedInterestFlags[name]
        setSeedInterestFlags({ ...seedInterestFlags, [name] : !val});
    };

    const getCheckBoxField = (seedInterestField: string) => {
        return (
            <Form.Check type="checkbox"
                        onClick={() => toggleSeedInterestFlag(seedInterestField)}
                        {...register(seedInterestField)}
                        name={seedInterestField}
                        label={EFieldName[seedInterestField]} />
        )
    }

    const onSubmit = async (data) => {
        const payload : IOrganisation = {...data, "OrganisationOwnerId": userOrgID.toString()};
        const result  = await UpdateUserRecord(token, cmsCollections.Organisations, payload);

        // update user record - user wanted to create a new org ; set the user's org to the one that just got created
        const updateUserProfilePayload = {
            "id": userID.toString(), 
            "organisation": result.data.orgName,
            "isOrgManager": true,
        }
        const result_updateUser = await UpdateUserRecord(token, cmsCollections.Userprofiles, updateUserProfilePayload);

        showMessage('Organisation Updated!', EMessageModeEnum.SUCCESS);

        if (result_updateUser.statusText === "OK") {
            // the user is contactInfo tab, and hits update, then take user to seedInterests
            setKey("organisationDetails");
        }
    };

    const onSubmitOrgDetails = async(data2: IOrgSubmitData) => {
        
        const seedCollectorData = {
            seedCollectorCanopyTree: data2.seedCollectorCanopyTree,
            seedCollectorGroundLayer: data2.seedCollectorGroundLayer,
            seedCollectorOther: data2.seedCollectorOther,
            seedCollectorSeedMidStory: data2.seedCollectorSeedMidStory,
            seedCollectorWetLand: data2.seedCollectorWetLand,
            seedCollectorLocation: data2.seedCollectorLocation,
            seedCollectorDistance: data2.seedCollectorDistance,
            seedCollectorMonths: data2.seedCollectorMonths,
            seedCollectorSpecialistCleaning: data2.seedCollectorSpecialistCleaning,
            seedCollectorSpecialistStorage: data2.seedCollectorSpecialistStorage,
            seedCollectorServiceExpertiseDescription: data2.seedCollectorServiceExpertiseDescription
        }

        const seedProductionAreaData = {
            seedProductionAreaCanopyTree: data2.seedProductionAreaCanopyTree,
            seedProductionAreaGroundLayer: data2.seedProductionAreaGroundLayer,
            seedProductionAreaSeedMidStory: data2.seedProductionAreaSeedMidStory,
            seedProductionAreaWetLand: data2.seedProductionAreaWetLand,
            seedProductionAreaOther: data2.seedProductionAreaOther,
            seedProductionAreaLocation: data2.seedProductionAreaLocation,
            seedProductionAreaDistance: data2.seedProductionAreaDistance,
            seedProductionAreaMonths: data2.seedProductionAreaMonths,
            seedProductionAreaSpecialistCleaning: data2.seedProductionAreaSpecialistCleaning,
            seedProductionAreaSpecialistStorage: data2.seedProductionAreaSpecialistStorage,
            seedProductionAreaServiceExpertiseDescription: data2.seedProductionAreaServiceExpertiseDescription,
        }

        const researchOrganisationData = {
            researchOrganisationDescription: data2.ResearchOrgDescription,
        }

        const nurseryData = {
            nurseryServiceInNT: data2.nurseryServiceInNT,
            nurseryServiceInWA: data2.nurseryServiceInWA,
            nurseryServiceInSA: data2.nurseryServiceInSA,
            nurseryServiceInVIC: data2.nurseryServiceInVIC,
            nurseryServiceInTAS: data2.nurseryServiceInTAS,
            nurseryServiceInACT: data2.nurseryServiceInACT,
            nurseryServiceInNSW: data2.nurseryServiceInNSW,
            nurseryServiceInQLD: data2.nurseryServiceInQLD,
            nurseryCapacity: data2.nurseryCapacity,
        }

        const landscapeRestorationData = {
            landscapeRestorationNT: data2.landscapeRestorationNT,
            landscapeRestorationWA: data2.landscapeRestorationWA,
            landscapeRestorationSA: data2.landscapeRestorationSA,
            landscapeRestorationVIC: data2.landscapeRestorationVIC,
            landscapeRestorationTAS: data2.landscapeRestorationTAS,
            landscapeRestorationACT: data2.landscapeRestorationACT,
            landscapeRestorationNSW: data2.landscapeRestorationNSW,
            landscapeRestorationQLD: data2.landscapeRestorationQLD,
        }

        const seedBankData = {
            seedBankNT: data2.seedBankNT,
            seedBankWA: data2.seedBankWA,
            seedBankSA: data2.seedBankSA,
            seedBankVIC: data2.seedBankVIC,
            seedBankTAS: data2.seedBankTAS,
            seedBankACT: data2.seedBankACT,
            seedBankNSW: data2.seedBankNSW,
            seedBankQLD: data2.seedBankQLD,
            seedBankDescription: data2.seedBankDescription,
        }

        const seedTestingData = {
            seedTestingNT: data2.seedTestingNT,
            seedTestingWA: data2.seedTestingWA,
            seedTestingSA: data2.seedTestingSA,
            seedTestingVIC: data2.seedTestingVIC,
            seedTestingTAS: data2.seedTestingTAS,
            seedTestingACT: data2.seedTestingACT,
            seedTestingNSW: data2.seedTestingNSW,
            seedTestingQLD: data2.seedTestingQLD,
        }

        const otherServiceData = {
            otherServiceNT: data2.otherServiceNT,
            otherServiceWA: data2.otherServiceWA,
            otherServiceSA: data2.otherServiceSA,
            otherServiceVIC: data2.otherServiceVIC,
            otherServiceTAS: data2.otherServiceTAS,
            otherServiceACT: data2.otherServiceACT,
            otherServiceNSW: data2.otherServiceNSW,
            otherServiceQLD: data2.otherServiceQLD,
        }

        const orgData : IOrgSeedInterestData = {
            seedCollectorData: [seedCollectorData],
            seedProductionAreaData: [seedProductionAreaData],
            researchOrganisationData: [researchOrganisationData],
            nurseryData: [nurseryData],
            landscapeRestorationData: [landscapeRestorationData],
            seedBankData: [seedBankData],
            seedTestingData: [seedTestingData],
            otherServiceData: [otherServiceData]
        }

        const payload : IOrganisation = {
            id: userOrgID, 
            orgData: orgData,
            ...data2
        }
        UpdateUserRecord(token, cmsCollections.Organisations, payload);     

        showMessage('Organisation Seed Interests Updated', EMessageModeEnum.SUCCESS);
    }

  return (
    <Layout>
    <ToastContainer message={message} mode={messageMode} />
    {
        <Container className="fl-page">
            <h3>Manage Organisation</h3>
            <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                <Tabs defaultActiveKey="contactInfo" id="manage-organisation" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="contactInfo" title="Contact Info">
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <br></br>
                                    <h4>My Organisation</h4>
                                    <Row>
                                        <Col sm={9}>
                                            <Row >
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgName">
                                                        <Form.Label>Name</Form.Label>
                                                        <FormControl type="text" name="orgName" {...register('orgName', { required: "Please enter your organisation's name." })} placeholder="e.g. company name"  />
                                                    </Form.Group>
                                                </Col>
                                                <br></br>
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgAddress">
                                                        <Form.Label>Address</Form.Label>
                                                        <FormControl type="text" name="orgAddress" {...register('orgAddress', { required: "Please enter your organisation's address." })} placeholder="e.g. road number and name" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Group controlId="formOrgPostcode">
                                                        <Form.Label>PostCode</Form.Label>
                                                        <FormControl type="number" name="orgPostcode" {...register('orgPostcode', { required: "Please enter your organisation's postcode." })} placeholder="3000"  />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group controlId="formOrgState">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control as="select" name="orgState" {...register('orgState', { required: "Please enter your organisation's state." })}>
                                                        <option selected disabled>Select State</option>
                                                        <option>Australian Capital Territory</option>
                                                        <option>South Australia</option>
                                                        <option>Northern Territory</option>
                                                        <option>Western Australia</option>
                                                        <option>Tasmania</option>
                                                        <option>Victoria</option>
                                                        <option>Queensland</option>
                                                    </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgContact">
                                                        <Form.Label>Contact Number</Form.Label>
                                                        <FormControl type="number" {...register('orgContactNumber', { required: "Please enter your organisation's contact number." })} name="orgContactNumber" placeholder="e.g. 03 1234 1234"  />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgWebsite">
                                                        <Form.Label>Website</Form.Label>
                                                        <FormControl type="text" name="orgWebsite" {...register('orgWebsite')} placeholder="e.g. https://www.florabank.org.au/"  />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <button className="btn btn-primary" type="submit">Add Organisation</button>   
                                            <span>&nbsp;</span>
                                            <Link to="/userprofile"><button className="btn btn-warning">Back to User Profile</button></Link>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="organisationDetails" title="Organisation Details">
                        <Row >
                            <Col>
                                <Form onSubmit={handleSubmit(onSubmitOrgDetails)}>
                                <br></br>
                                    <h4>Organisation Seed Interests</h4>
                                    <p>(Select as many as required)</p>
                                    <Row>
                                        <Col sm={9}>

                                            <br></br>
                                            <Row >
                                                <div className="panel panel-default">
                                                    <div className="panel-body">
                                                    <Form.Group controlId="formUserSeedInterest">
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedCollector)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isResearchOrganisation)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedBank)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedProductionArea)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isLandscapeRestoration)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isOther)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isNursery)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedTesting)}
                                                    </Form.Group>
                                                    </div>
                                                </div>
                                            </Row>
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedCollector ? {display: ''} : {display: 'none'}}>
                                                <SeedCollector register={register} />
                                            </div>
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedProductionArea ? {display: ''} : {display: 'none'}}>
                                                <SeedProductionArea register={register} />
                                            </div>      
                                            <br></br>
                                            <div style={ seedInterestFlags.isResearchOrganisation ? {display: ''} : {display: 'none'}}>
                                                <ResearchOrganisation  register={register} />
                                            </div>   
                                            <br></br>
                                            <div style={ seedInterestFlags.isNursery ? {display: ''} : {display: 'none'}}>
                                                <Nursery  register={register} />
                                            </div>       
                                            <br></br>
                                            <div style={ seedInterestFlags.isLandscapeRestoration ? {display: ''} : {display: 'none'}}>
                                                <LandscapeRestoration  register={register} />
                                            </div>     
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedBank ? {display: ''} : {display: 'none'}}>
                                                <SeedBank  register={register} />
                                            </div> 
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedTesting ? {display: ''} : {display: 'none'}}>
                                                <SeedTesting  register={register} />
                                            </div> 
                                            <br></br>
                                            <div style={ seedInterestFlags.isOther ? {display: ''} : {display: 'none'}}>
                                                <OtherService  register={register} />
                                            </div>                                  
                                            <br></br>
                                            <button className="btn btn-primary" type="submit">Update</button>   
                                            <br></br>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </LoadingOverlay>
        </Container>
    }
    </Layout>
  );
};

export default ManageOrg;